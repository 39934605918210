import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { SKINS, skin } from '../constants/skin';

const initialState = {
  campaign: {},
  impressionSegment: null,
  conversionSegment: null,
  impressionSite: null,
  conversionSite: null,
  deviceImpression: null,
  deviceConversion: null,
  advancedChartData: null,
  isAdvancedChartLoading: false,
  loading: false,
  accessDenied: false,
  totals: {},
  firstPartySegments: [],
  alerts: [],
  configAlerts: [],
  configuration: [],
  tags: [],
  customers: [],
  impressionSegmentList: [],
  conversionSegmentList: [],
  impressionSiteList: [],
  conversionSiteList: [],
  devicesList: [],
  isEditLoading: false,
};

export const getAudienceSegment = createAsyncThunk(
  'campaign/audience-segment',
  async (params) => {
    const { data } = await http.get('/performance/audience-segment', {
      params,
    });

    return data;
  },
);

export const getAudienceSite = createAsyncThunk(
  'campaign/audience-site',
  async (params) => {
    const { data } = await http.get('/performance/audience-site', { params });

    return data;
  },
);

export const getConversionSite = createAsyncThunk(
  'campaign/conversion-site',
  async (params) => {
    const { data } = await http.get('/performance/conversion-site', { params });

    return data;
  },
);

export const getAudienceDevice = createAsyncThunk(
  'campaign/audience-device',
  async () => {
    const { data } = await http.get('/performance/audience-device');

    return data;
  },
);

export const getCampaign = createAsyncThunk(
  '/campaign',
  async ({ id, date }, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`/campaign/${id}`, { params: { date } });

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      }
      return rejectWithValue(null);
    }
  },
);

export const getFirstPartySegments = createAsyncThunk(
  'campaign/first-party-segments',
  async (params) => {
    const { data } = await http.get('segments', { params: { ...params, is_visible: 1 } });

    return data;
  },
);

export const getCampaignByCustomerId = createAsyncThunk(
  '/campaign',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get('/campaign/by-customer', { params });

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      }
      return rejectWithValue(null);
    }
  },
);

export const getCustomers = createAsyncThunk(
  'campaign/customers',
  async ({ partner_id, is_active }) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
        is_active,
      },
    });

    return data;
  },
);

export const stopCampaign = createAsyncThunk(
  '/campaign/stop',
  async (id) => {
    const { data } = await http.post(`/campaign/stop/${id}`);

    return data;
  },
);

export const deleteCampaign = createAsyncThunk(
  '/campaign/delete',
  async ({ id, customer_partner_id, callback }) => {
    const { data } = await http.delete(`/campaign/delete/${id}/${customer_partner_id}`);
    callback();

    return data;
  },
);

export const getPerformanceTotals = createAsyncThunk(
  '/reports/performance/totals',
  async (params) => {
    const { data } = await http.get('/performance/totals', { params });

    return data;
  },
);

export const getCampaignAlerts = createAsyncThunk(
  '/campaign/alerts',
  async (params) => {
    const { data } = await http.get('/alerts/notifications', { params });

    return data;
  },
);

export const getCampaignConfigAlerts = createAsyncThunk(
  '/campaign/config-alerts',
  async (params) => {
    const { data } = await http.get('/campaign/config-alerts', { params });

    return data;
  },
);

export const createConfiguration = createAsyncThunk(
  '/campaign/create-configuration',
  async ({ callback, ...data }) => {
    const { data: res } = await http.post('/customers/create-configuration', data);
    callback();

    return res;
  },
);

export const getCampaignConfiguration = createAsyncThunk(
  '/campaign/configuration',
  async (params) => {
    const { data } = await http.get('/configuration', { params });

    return data;
  },
);

export const getCampaignTags = createAsyncThunk(
  '/campaign/tags',
  async (params) => {
    const url = skin === SKINS.DATONICS ? '/tags/datonics' : '/tags';

    const { data } = await http.get(url, { params });

    return data;
  },
);

export const updateCampaignConfiguration = createAsyncThunk(
  '/campaign/configuration/update',
  async ({ data, id }) => {
    const res = await http.patch(`/configuration/${id}`, data);

    return res;
  },
);

export const updateCampaignAlerts = createAsyncThunk(
  '/campaign/alerts/update',
  async ({
    data, partner_id, customer_id, id, date, status,
  }, { dispatch }) => {
    const res = await http.patch(`/campaign/alerts/${partner_id}/${customer_id}`, { data, status });
    dispatch(getCampaign({ id, date }));
    dispatch(getCampaignAlerts({ customer_id, partner: partner_id }));
    dispatch(getCampaignAlerts({ customer_id, partner: partner_id }));

    return res;
  },
);

export const updateCampaign = createAsyncThunk(
  '/campaign/update',
  async ({
    data, partner_id, customer_id, id, date,
  }, { dispatch }) => {
    const res = await http.patch(`/campaign/${partner_id}/${customer_id}`, data);
    dispatch(getCampaign({ id, date }));

    return res;
  },
);

export const updateTimeAlias = createAsyncThunk(
  '/campaign/timeAlias/update',
  async ({
    data, id, date, onClose,
  }, { dispatch }) => {
    const res = await http.patch(`/timeAlias/${id}`, data);

    onClose();
    dispatch(getCampaign({ id, date }));

    return res;
  },
);

export const createTag = createAsyncThunk(
  '/campaign/tag/create',
  async ({
    data, callback, partner_id, customer_id,
  }, { rejectWithValue }) => {
    try {
      const res = await http.post(`/tags/${partner_id}/${customer_id}`, data);
      callback();

      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      }
      return rejectWithValue('error');
    }
  },
);

export const deleteTag = createAsyncThunk(
  '/campaign/tag/delete',
  async (data) => {
    const url = skin === SKINS.DATONICS ? '/tags/datonics' : '/tags';
    const res = await http.delete(url, { params: { tagsIds: JSON.stringify(data) } });

    return res;
  },
);

export const createNewSegment = createAsyncThunk(
  '/segment/create',
  async ({
    data, callback, partner_id, customer_id,
  }, { rejectWithValue }) => {
    try {
      const res = await http.post(`/segments/${partner_id}/${customer_id}`, data);
      callback(res.data[0]);
      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      }
      return rejectWithValue('error');
    }
  },
);

export const getImpressionSite = createAsyncThunk(
  '/campaign/impression-site',
  async (params) => {
    const { data } = await http.get('/campaign/impression-site', { params });

    return data;
  },
);

export const getAdvancedChart = createAsyncThunk(
  '/campaign/advanced-chart',
  async (params) => {
    const { data } = await http.get('/campaign/device', { params });

    return data;
  },
);

export const getDevice = createAsyncThunk(
  '/campaign/device',
  async (params) => {
    const { data } = await http.get('/campaign/device', { params });

    return data;
  },
);

export const getCampaignAudienceSegment = createAsyncThunk(
  '/campaign-attributable-matches-audience',
  async (params) => {
    const { data } = await http.get('/campaign/attributable-matches-audience', { params });

    return data;
  },
);

export const campaignSlice = createSlice({
  name: 'campaign',
  reducers: {
    clearCampaignReducer: () => initialState,
    clearCampaignTags: (state) => {
      state.tags = [];
    },
    resetChartData: (state) => {
      state.advancedChartData = null;
    },
    setTags: (state, { payload }) => {
      state.tags = payload;
    },
  },
  initialState,
  extraReducers: {
    [getAudienceDevice.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceDevice.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.devicesList = payload;
    },
    [getAudienceSite.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.impressionSiteList = payload;
    },
    [getConversionSite.pending]: (state) => {
      state.loading = true;
    },
    [getConversionSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.conversionSiteList = payload;
    },
    [getAudienceSegment.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceSegment.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg.attribute_type_id === 16 ? 'impressionSegmentList' : 'conversionSegmentList'] = payload;
      state.loading = false;
    },
    [getCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getCampaign.rejected]: (state) => {
      state.loading = false;
      state.accessDenied = true;
    },
    [getCampaignByCustomerId.pending]: (state) => {
      state.loading = true;
    },
    [getCampaignByCustomerId.rejected]: (state) => {
      state.loading = false;
      state.accessDenied = true;
    },
    [getCampaignAudienceSegment.pending]: (state) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload;
    },
    [getImpressionSite.pending]: (state) => {
      state.loading = true;
    },
    [getAdvancedChart.pending]: (state) => {
      state.isAdvancedChartLoading = true;
    },
    [getAdvancedChart.fulfilled]: (state) => {
      state.isAdvancedChartLoading = false;
    },
    [getAdvancedChart.rejected]: (state) => {
      state.isAdvancedChartLoading = false;
    },
    [getDevice.pending]: (state) => {
      state.loading = true;
    },
    [getCampaignAlerts.pending]: (state) => {
      state.loading = true;
    },
    [getCampaignAlerts.fulfilled]: (state, { payload }) => {
      state.alerts = payload;
      state.loading = false;
    },
    [getCampaignTags.pending]: (state) => {
      state.isEditLoading = true;
    },
    [getCampaignTags.fulfilled]: (state, { payload }) => {
      state.tags = payload;
      state.isEditLoading = false;
    },
    [getCampaignConfiguration.pending]: (state) => {
      state.isEditLoading = true;
    },
    [getFirstPartySegments.fulfilled]: (state, { payload }) => {
      state.firstPartySegments = payload;
    },
    [getCampaignConfiguration.rejected]: (state) => {
      state.isEditLoading = false;
    },
    [getCampaignConfiguration.fulfilled]: (state, { payload }) => {
      state.configuration = payload;
      state.isEditLoading = false;
    },
    [getCampaignConfigAlerts.pending]: (state) => {
      state.isEditLoading = true;
    },
    [getCampaignConfigAlerts.fulfilled]: (state, { payload }) => {
      state.configAlerts = payload;
      state.isEditLoading = false;
    },
    [updateCampaignConfiguration.pending]: (state) => {
      state.isEditLoading = true;
    },
    [updateCampaignConfiguration.fulfilled]: (state) => {
      state.isEditLoading = false;
      toast.success('Configuration updated');
    },
    [updateCampaign.pending]: (state) => {
      state.isEditLoading = true;
    },
    [updateCampaign.fulfilled]: (state, { meta: { arg } }) => {
      state.isEditLoading = false;
      state.campaign.start_date = arg.data.start_date;
      state.campaign.end_date = arg.data.end_date;
      toast.success('Time updated');
    },
    [deleteTag.pending]: (state) => {
      state.isEditLoading = true;
    },
    [deleteTag.fulfilled]: (state, { meta: { arg } }) => {
      state.isEditLoading = false;
      state.tags = state.tags.filter((tag) => !arg.includes(tag.id));
      toast.success('Tags deleted');
    },
    [createTag.pending]: (state) => {
      state.isEditLoading = true;
    },
    [createTag.rejected]: (state) => {
      state.isEditLoading = false;
    },
    [createTag.fulfilled]: (state, { payload }) => {
      state.isEditLoading = false;
      const errors = payload?.data?.errors;
      if (errors?.length) {
        errors.forEach((message) => toast.error(message, {
          duration: 10000,
        }));
      } else {
        toast.success('Tags created');
      }
    },
    [updateCampaignAlerts.pending]: (state) => {
      state.isEditLoading = true;
    },
    [updateCampaignAlerts.fulfilled]: (state) => {
      state.isEditLoading = false;
      toast.success('Alerts updated');
    },
    [getDevice.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg.hasAudienceDevice ? 'deviceImpression' : 'deviceConversion'] = payload;
      state.loading = false;
    },
    [getImpressionSite.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg.hasConversionSiteFilter ? 'conversionSite' : 'impressionSite'] = payload;
      state.loading = false;
    },
    [getAdvancedChart.fulfilled]: (state, { payload }) => {
      state.advancedChartData = payload;
      state.isAdvancedChartLoading = false;
    },
    [getCampaignAudienceSegment.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg.hasAudienceSegment ? 'impressionSegment' : 'conversionSegment'] = payload;
      state.loading = false;
    },
    [getCampaign.fulfilled]: (state, { payload }) => {
      state.campaign = payload;
      state.loading = false;
    },
    [getCampaignByCustomerId.fulfilled]: (state, { payload }) => {
      state.campaign = payload;
      state.loading = false;
    },
    [getPerformanceTotals.pending]: (state) => {
      state.loading = true;
    },
    [getPerformanceTotals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.totals = payload?.[0];
    },
    [createNewSegment.pending]: (state) => {
      state.loading = true;
    },
    [createNewSegment.fulfilled]: (state) => {
      state.loading = false;
    },
    [stopCampaign.pending]: (state) => {
      state.loading = true;
    },
    [stopCampaign.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.campaign.end_date = payload.end_date;
      state.campaign.is_active = payload.is_active;
    },
  },
});

export const {
  clearCampaignReducer, clearCampaignTags, resetChartData, setTags,
} = campaignSlice.actions;
export default campaignSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  DEFAULT_PAGE_SIZE,
  PARTNER_KEY,
} from '../constants';
import { DATE_FORMAT } from '../constants/formats';
import { DATE_FILTER, LOCAL_STORAGE_FAVORITE } from '../constants/storage';
import http from '../services/Http';
import { createCustomer } from './create-customer';
import { getLastSelectByKey, setLastSelect, setLastSelectPartner } from '../utility/Utils';
import { CONVERSION_SEGMENT, IMPRESSION_SEGMENT } from '../constants/segments';
import { DEFAULT_ERROR } from '../constants/errors';

const initialState = {
  partner: getLastSelectByKey(PARTNER_KEY),
  impressionSegment: [],
  conversionSegment: [],
  audienceActivationSegments: [],
  partners: [],
  alertsNotifications: [],
  alertsNotificationsCount: 0,
  alertsTypes: [],
  statusesFilter: [],
  isReloadHomeCampaigns: false,
  devkitLoading: false,
  loading: false,
  table: {
    data: [],
    loading: false,
    headers: {},
    total: 0,
    lastPage: 1,
    from: 0,
    to: 0,
    currentPage: 1,
    page: 1,
  },
  impressions: {
    labels: [],
    values: [],
  },
  frequency: {
    labels: [],
    values: [],
  },
  conversion: {
    labels: [],
    values: [],
  },
  isFavorite: JSON.parse(localStorage.getItem(LOCAL_STORAGE_FAVORITE)),
  favorites: [],
  campaignStatuses: {},
  statuses: [],
  conversions: [],
  dateFilter: localStorage.getItem(DATE_FILTER) || '-7',
  startDate: null,
  endDate: null,
  devKit: null,
  devKitList: [],
};

export const createAlerts = createAsyncThunk(
  'shedule',
  async () => {
    const { data } = await http.get('/schedule/ping');

    return data;
  },
);

export const getDevKit = createAsyncThunk(
  'dev-kit',
  async () => {
    const { data } = await http.get('/dev-kit');

    return data;
  },
);

export const getDevKitList = createAsyncThunk(
  'dev-kit/list',
  async () => {
    const { data } = await http.get('/dev-kit/list');

    return data;
  },
);

export const setCurrentDevKit = createAsyncThunk(
  'dev-kit/set',
  async (params) => {
    try {
      const { data } = await http.post('/dev-kit', params);
      toast.success('Success');

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      toast.error(errorMessage || DEFAULT_ERROR);
    }
  },
);

export const getFavorites = createAsyncThunk(
  'reports/favorites',
  async (id) => {
    const { data } = await http.get('/dashboard/favorites', { params: { id } });

    return data;
  },
);

export const addFavorite = createAsyncThunk(
  'reports/favorites/add',
  async ({ id, favorite }) => {
    const { data } = await http.post('/dashboard/favorites', { id, favorite });

    return data;
  },
);

export const sendHelp = createAsyncThunk(
  'reports/favorites/add',
  async (body) => {
    const { data } = await http.post('/mail/send-help', body);

    return data;
  },
);

export const removeFavorite = createAsyncThunk(
  'reports/favorites/remove',
  async ({ id, favorite }) => {
    const { data } = await http.delete('/dashboard/favorites', { params: { id, favorite } });

    return data;
  },
);

export const getPartners = createAsyncThunk(
  'reports/partners',
  async (user_type = undefined) => {
    const { data } = await http.get(`/dashboard/partners?user_type=${user_type}`);

    return data;
  },
);

export const getAlertsNotifications = createAsyncThunk(
  'reports/alerts/notifications',
  async (params) => {
    const { data } = await http.get('/alerts/notifications', { params });

    return data;
  },
);

export const getAlertsTypes = createAsyncThunk(
  'reports/alerts/types',
  async (params) => {
    const { data } = await http.get('/alerts/types', { params });

    return data;
  },
);

export const markAsViewNotification = createAsyncThunk(
  'reports/alerts/notifications/view',
  async (id) => {
    const { data } = await http.patch('/alerts/notifications/mark-as-viewed', { id });

    return data;
  },
);

export const getCampaignStatuses = createAsyncThunk(
  'reports/campaign-statuses',
  async (_, { getState }) => {
    const { homeData: { dateFilter, partner } } = getState();
    if (!partner) return {};

    const { data } = await http.get('/dashboard/campaign-statuses', { params: { date: dateFilter, partner } });

    return data;
  },
);

export const getCampaignAudienceSegment = createAsyncThunk(
  'home-data/attributable-matches-audience',
  async (params) => {
    const { data } = await http.get('/performance/audience-segment', {
      params,
    });

    return data;
  },
);

export const getHomeData = createAsyncThunk(
  'reports/home',
  async ({ page, perPage, filters }, { getState }) => {
    const { homeData } = getState();
    const { startDate, endDate, dateFilter } = homeData;

    if (!filters.partner) return;

    const { data } = await http.get('/dashboard/home', {
      params: {
        page,
        favorite: homeData.isFavorite || null,
        isTable: true,
        per_page: perPage,
        hasDateRange: !!homeData.endDate,
        start_date: startDate && moment(startDate).format(DATE_FORMAT),
        end_date: endDate && moment(endDate).format(DATE_FORMAT),
        ...{
          ...filters,
        },
        date: startDate ? null : dateFilter,
      },
    });
    return data;
  },
);

export const reportsSlice = createSlice({
  name: 'reports',
  reducers: {
    setDateFilter: (state, action) => {
      if (action.payload?.endDate) {
        state.endDate = moment(action.payload.endDate);
        state.startDate = moment(action.payload.startDate);
        state.dateFilter = null;
      } else {
        state.dateFilter = action.payload;
        state.endDate = null;
        state.startDate = null;
      }
    },
    setStatusFilter: (state, action) => {
      state.statusesFilter = action.payload;
    },
    setPartner: (state, action) => {
      setLastSelect({ value: action.payload, key: PARTNER_KEY });
      state.partner = action.payload;
    },
    handleChangePage: (state, { payload: { page, isFavorite } }) => {
      state.table.page = page;
      state.isFavorite = isFavorite !== undefined ? isFavorite : state.isFavorite;
    },
    addNewSegment(state, { payload: { segmentType, segment } }) {
      state[segmentType] = [
        ...state[segmentType],
        segment,
      ];
    },
    resetHomeData: () => initialState,
    addCustomer(state, { payload }) {
      state.table.data = [payload, ...state.table.data];
    },
  },
  initialState,
  extraReducers: {
    [getHomeData.pending]: (state) => {
      state.table.loading = true;
    },
    [createCustomer.fulfilled]: (state) => {
      state.isReloadHomeCampaigns = true;
    },
    [setCurrentDevKit.pending]: (state) => {
      state.devkitLoading = true;
    },
    [setCurrentDevKit.fulfilled]: (state) => {
      state.devkitLoading = false;
    },
    [getDevKit.fulfilled]: (state, { payload }) => {
      state.devKit = payload;
    },
    [getDevKitList.fulfilled]: (state, { payload }) => {
      state.devKitList = payload;
    },
    [getCampaignAudienceSegment.fulfilled]: (state, { payload, meta: { arg } }) => {
      if (arg.attribute_type_id === IMPRESSION_SEGMENT) {
        state.impressionSegment = payload;
      } else if (arg.attribute_type_id === CONVERSION_SEGMENT) {
        state.conversionSegment = payload;
      } else {
        state.audienceActivationSegments = payload;
      }

      state.loading = false;
    },
    [getFavorites.pending]: (state) => {
      state.loading = true;
    },
    [addFavorite.pending]: (state) => {
      state.loading = true;
    },
    [removeFavorite.pending]: (state) => {
      state.loading = true;
    },
    [getPartners.pending]: (state) => {
      state.loading = true;
    },
    [getCampaignStatuses.pending]: (state) => {
      state.loading = true;
    },
    [getCampaignStatuses.fulfilled]: (state, { payload }) => {
      state.campaignStatuses = payload;
      state.loading = false;
    },
    [markAsViewNotification.pending]: (state) => {
      state.loading = true;
    },
    [markAsViewNotification.fulfilled]: (state, { meta: { arg } }) => {
      state.alertsNotifications = state.alertsNotifications.map((item) => (item.alert_id === arg
        ? { ...item, viewed: 1 }
        : item));
      state.loading = false;
      state.alertsNotificationsCount -= 1;
    },
    [getAlertsNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getAlertsNotifications.fulfilled]: (state, { payload }) => {
      state.alertsNotifications = payload.data;
      state.alertsNotificationsCount = payload.count;
      state.loading = false;
    },
    [getAlertsTypes.pending]: (state) => {
      state.loading = true;
    },
    [getAlertsTypes.fulfilled]: (state, { payload }) => {
      state.alertsTypes = payload;
      state.loading = false;
    },
    [getPartners.fulfilled]: (state, { payload }) => {
      state.partners = payload;
      state.loading = false;
    },
    [removeFavorite.fulfilled]: (state, { meta }) => {
      state.favorites = state.favorites.filter((id) => id !== meta.arg.favorite);
      state.loading = false;

      if (meta.arg.isFavorite) {
        const total = state.table.total - 1;
        state.table.data = state.table.data.filter((item) => item.id !== meta.arg.favorite);
        state.table.total = total;
        state.table.page = total % DEFAULT_PAGE_SIZE === 0
          ? state.table.page - 1
          : state.table.page;
      }
    },
    [addFavorite.fulfilled]: (state, { meta }) => {
      state.favorites.push(meta.arg.favorite);
      state.loading = false;
    },
    [getFavorites.fulfilled]: (state, { payload }) => {
      state.favorites = payload;
      state.loading = false;
    },
    [getHomeData.fulfilled]: (
      state,
      {
        payload,
        meta,
      },
    ) => {
      if (!payload) {
        state.table.loading = false;

        return;
      }
      const {
        reports: { data, pagination, headers },
        impressions,
        frequency,
        conversion,
      } = payload;
      state.table = {
        ...state.table,
        loading: false,
        data: data.map((row) => ({
          ...row,
          user_id: 1,
          start_date: row.start_date && row.start_date.replace(/T.*/, ''),
        })),
        headers,
        ...pagination,
        page: meta.arg.page || 1,
      };

      state.isReloadHomeCampaigns = false;
      state.impressions = impressions;
      state.frequency = frequency;
      state.statuses = [1];
      state.conversion = {
        values: conversion.data,
        labels: conversion.labels,
      };
    },
  },
});

export const {
  resetHomeData,
  setPartner,
  addNewSegment,
  addCustomer,
  setDateFilter,
  handleChangePage,
  setStatusFilter,
} = reportsSlice.actions;
export default reportsSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { EXPORT_KEYS_DEALS } from '../constants/deals';
import { exportToExcel } from '../utility';
import { DEFAULT_ERROR } from '../constants/errors';
import { actionCreator } from '../utility/action';
import { ACTION_TYPES } from '../constants';

const initialState = {
  table: {
    data: [],
    loading: false,
    headers: {},
    total: 0,
    page: 1,
  },
  options: {
    ad_format: [],
    browsers: [],
    countries: [],
    dsp: [],
    operations_systems: [],
    platforms: [],
    activation_platforms: [],
    audiences: [],
    buyerSeatIds: [],
  },
  domains: [],
  deals: [],
  isDomainsLoading: false,
  isDrawerLoading: false,
  isFileLoading: false,
  isLoading: false,
};

export const getDeal = createAsyncThunk(
  'deal',
  async ({ filters, ...params }) => {
    if (!filters.partner_id || !filters.customer_id) return [];

    const { data } = await http.get('/deal', {
      params: {
        ...params,
        ...{
          ...filters,
        },
      },
    });
    return data;
  },
);

export const dealRefresh = createAsyncThunk(
  'deal/refresh',
  async ({ filters, ...params }) => {
    if (!filters.partner_id || !filters.customer_id) return [];

    const { data } = await http.get('/deal', {
      params: {
        ...params,
        ...{
          ...filters,
        },
      },
    });
    return data;
  },
);

export const getDealCsv = createAsyncThunk(
  'deal/csv',
  async (params) => {
    const { data } = await http.get('/deal/csv', { params });

    exportToExcel({
      name: 'deals', data, keys: EXPORT_KEYS_DEALS, columnWidths: [4, 6, 10, 10, 6, 6, 8, 8, 8, 8, 10, 10, 10, 10, 10],
    });
    return data;
  },
);

export const validateFile = createAsyncThunk(
  'deal/validate/file',
  async ({ formData, errorHandler }, { rejectWithValue }) => {
    try {
      const { data } = await http.post('deal/validate/file', formData);

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      errorHandler();

      return rejectWithValue(errorMessage || DEFAULT_ERROR);
    }
  },
);

export const createDeal = createAsyncThunk(
  'deal/create',
  async ({ formData, callback, errorCallback }, { rejectWithValue }) => {
    try {
      const { data } = await http.post('/deal', formData);
      toast.success('Deal has been created!');

      if (callback) callback();

      return data;
    } catch (e) {
      if (errorCallback) errorCallback();

      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }

      return rejectWithValue(errorMessage || DEFAULT_ERROR);
    }
  },
);

export const editDeal = createAsyncThunk(
  'deal/edit',
  async ({ formData, callback, errorCallback }, { rejectWithValue }) => {
    try {
      const { data } = await http.put('/deal', formData);
      toast.success('Deal has been updated!');

      if (callback) callback();

      return data;
    } catch (e) {
      if (errorCallback) errorCallback();

      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }

      return rejectWithValue(errorMessage || DEFAULT_ERROR);
    }
  },
);

export const dealExists = actionCreator({
  id: '/deal/exists',
  route: '/deal/exists',
  actionType: ACTION_TYPES.post,
});

export const deleteDeal = actionCreator({
  id: 'deal/delete',
  route: '/deal',
  actionType: ACTION_TYPES.delete,
  toastSuccess: 'Deal has been deleted!',
});

export const getDealsList = actionCreator({
  id: 'deal/list',
  route: '/deal/list',
  actionType: ACTION_TYPES.get,
});

export const getDealDsp = actionCreator({
  id: 'deal/dsp',
  route: '/deal/dsp',
  actionType: ACTION_TYPES.get,
});

export const getDealDspBuyerSeatIds = actionCreator({
  id: 'deal/dsp/buyer-seat-ids',
  route: '/deal/dsp/buyer-seat-ids',
  actionType: ACTION_TYPES.get,
});

export const getDealOptions = actionCreator({
  id: 'deal/options',
  route: '/deal/options',
  actionType: ACTION_TYPES.get,
});

export const getDomainsTemplate = actionCreator({
  id: 'deal/domains/template',
  route: '/deal/domains/template',
  actionType: ACTION_TYPES.get,
});

export const dealSlice = createSlice({
  name: 'deal',
  reducers: {
    clearDomains(state) {
      state.domains = [];
    },
  },
  initialState,
  extraReducers: {
    [getDeal.pending]: (state) => {
      state.table.loading = true;
    },
    [getDeal.fulfilled]: (
      state,
      {
        payload,
        meta: { arg },
      },
    ) => {
      state.table = {
        ...state.table,
        data: payload.data,
        total: payload.total,
        page: arg.page,
        loading: false,
      };
    },
    [dealRefresh.fulfilled]: (
      state,
      {
        payload,
        meta: { arg },
      },
    ) => {
      state.table = {
        ...state.table,
        data: payload.data,
        total: payload.total,
        page: arg.page,
        loading: false,
      };
    },
    [createDeal.pending]: (state) => {
      state.isDrawerLoading = true;
    },
    [getDealDsp.fulfilled]: (state, { payload }) => {
      state.options.dsp = payload.data;
    },
    [getDealDspBuyerSeatIds.fulfilled]: (state, { payload }) => {
      state.options.buyerSeatIds = payload.data;
    },
    [createDeal.rejected]: (state) => {
      state.isDrawerLoading = false;
    },
    [createDeal.fulfilled]: (state) => {
      state.isDrawerLoading = false;
    },
    [editDeal.pending]: (state) => {
      state.isDrawerLoading = true;
    },
    [editDeal.rejected]: (state) => {
      state.isDrawerLoading = false;
    },
    [editDeal.fulfilled]: (state) => {
      state.isDrawerLoading = false;
    },
    [getDealOptions.pending]: (state) => {
      state.isLoading = true;
    },
    [getDealOptions.rejected]: (state) => {
      state.isLoading = false;
    },
    [getDealOptions.fulfilled]: (state, { payload }) => {
      state.options = { ...state.options, ...payload.data };
      state.isLoading = false;
    },
    [getDealCsv.rejected]: (state) => {
      state.isCsvLoading = false;
    },
    [getDealCsv.fulfilled]: (state) => {
      state.isCsvLoading = false;
    },
    [getDealCsv.pending]: (state) => {
      state.isCsvLoading = true;
    },
    [validateFile.pending]: (state) => {
      state.isFileLoading = true;
    },
    [validateFile.rejected]: (state) => {
      state.isFileLoading = false;
    },
    [validateFile.fulfilled]: (state) => {
      state.isFileLoading = false;
    },
    [getDealsList.pending]: (state) => {
      state.isLoading = true;
    },
    [getDealsList.rejected]: (state) => {
      state.isLoading = false;
    },
    [getDealsList.fulfilled]: (state, { payload }) => {
      state.deals = payload.data;
      state.isLoading = false;
    },
  },
});

export const { clearDomains } = dealSlice.actions;
export default dealSlice.reducer;

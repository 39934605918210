import { SvgIcon } from '@mui/material';
import React from 'react';

function EmptyStateIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '372px', ...sx }} width="372" height="330" viewBox="0 0 372 330" fill="none" {...props}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_2450_14737" style={{ maskType: 'alpha ' }} maskUnits="userSpaceOnUse" x="123" y="169" width="154" height="21">
          <path fillRule="evenodd" clipRule="evenodd" d="M258.08 182.131L248.223 173.096L237.674 180.926L233.253 174.834L215.797 180.926L175.586 174.834L152.212 181.287C159.359 181.048 166.621 180.926 173.979 180.926C210.794 180.926 245.245 183.989 274.702 189.316C275.836 184.277 276.602 179.093 276.963 173.793L273.627 169.71L258.08 182.131ZM123.844 182.88C133.053 182.146 142.525 181.611 152.211 181.288L134.944 170.02L123.844 182.88Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2450_14737)">
          <path fillRule="evenodd" clipRule="evenodd" d="M119.171 193.986H281.626V165.043H119.171V193.986Z" fill="#E0E2EE" />
        </g>
        <mask id="mask1_2450_14737" style={{ maskType: 'alpha ' }} maskUnits="userSpaceOnUse" x="82" y="165" width="40" height="23">
          <path fillRule="evenodd" clipRule="evenodd" d="M82.7578 181.541C83.051 183.531 83.4048 185.503 83.8166 187.453C95.8598 185.602 108.635 184.112 121.971 183.026L103.4 165.979L82.7578 181.541Z" fill="white" />
        </mask>
        <g mask="url(#mask1_2450_14737)">
          <path fillRule="evenodd" clipRule="evenodd" d="M78.0879 192.121H126.638V161.311H78.0879V192.121Z" fill="#E0E2EE" />
        </g>
        <mask id="mask2_2450_14737" style={{ maskType: 'alpha ' }} maskUnits="userSpaceOnUse" x="0" y="179" width="372" height="94">
          <ellipse cx="185.811" cy="225.879" rx="185.811" ry="46.5021" fill="#E8EBF2" />
        </mask>
        <g mask="url(#mask2_2450_14737)">
          <circle cx="179.507" cy="161.841" r="99.5066" fill="#E8EBF2" />
        </g>
        <path opacity="0.5" d="M128.844 205.824C128.844 205.824 162.764 221.774 176.221 227.895C186.88 232.744 241.765 248.254 248.303 243.845C254.842 239.436 269.278 227.336 264.512 219.045C259.746 210.755 234.681 195.87 222.694 193.695C210.707 191.52 176.43 201.407 176.43 201.407L147.193 206.428L128.844 205.824Z" fill="url(#paint0_linear_2450_14737)" />
        <path d="M153.995 134.509L116.196 162.303L126.274 138.657L164.129 114.625L153.995 134.509Z" fill="#E1E3ED" />
        <path d="M125.64 139.145L164.123 115.053V180.757L128.847 205.489L125.64 139.145Z" fill="#E4E6ED" />
        <path d="M164.129 115.053L223.137 128.858V194.586L164.129 180.781V115.053Z" fill="#EEF0F9" />
        <path d="M125.64 139.14L185.93 153.23V218.957L128.847 205.849L125.64 139.14Z" fill="#EEF0F9" />
        <path fillRule="evenodd" clipRule="evenodd" d="M172.77 195.18L170.013 191.928C171.961 189.331 173.116 186.01 173.147 182.244C173.23 172.146 165.2 162.676 155.216 161.094C145.231 159.513 137.061 166.418 136.978 176.515C136.895 186.612 144.925 196.083 154.909 197.664C158.615 198.251 162.059 197.669 164.95 196.197L167.721 199.464C169.093 201.083 171.346 201.44 172.735 200.258C174.118 199.076 174.137 196.798 172.77 195.18ZM164.828 185.825L163.705 184.488C162.332 182.869 160.08 182.512 158.69 183.694C157.295 184.874 157.276 187.152 158.649 188.764L159.759 190.074C158.313 190.571 156.68 190.714 154.962 190.442C148.923 189.486 144.062 183.753 144.112 177.645C144.162 171.537 149.104 167.357 155.144 168.314C161.184 169.27 166.044 175.003 165.994 181.111C165.973 182.866 165.563 184.464 164.828 185.825Z" fill="#D8DBEA" fillOpacity="0.7" />
        <path d="M184.951 153.591L223.435 129.5V195.203L184.951 219.294V153.591Z" fill="#D8DBEA" />
        <mask id="mask3_2450_14737" style={{ maskType: 'alpha ' }} maskUnits="userSpaceOnUse" x="184" y="129" width="40" height="91">
          <path d="M184.951 153.591L223.435 129.5V195.203L184.951 219.294V153.591Z" fill="white" />
        </mask>
        <g mask="url(#mask3_2450_14737)">
          <rect x="199.25" y="124.025" width="12.0948" height="100.745" fill="#D8DBEA" />
          <path fillRule="evenodd" clipRule="evenodd" d="M219.964 180.633L217.616 178.985C218.935 175.5 219.593 171.742 219.355 168.148C218.717 158.514 211.878 153.716 204.083 157.435C196.287 161.153 190.479 171.983 191.117 181.617C191.755 191.251 198.594 196.049 206.389 192.331C209.282 190.951 211.893 188.597 214.016 185.687L216.375 187.342C217.544 188.162 219.303 187.324 220.29 185.475C221.272 183.629 221.129 181.455 219.964 180.633ZM213.2 175.9L212.243 175.217C211.074 174.396 209.316 175.235 208.328 177.084C207.336 178.935 207.48 181.108 208.649 181.922L209.595 182.586C208.516 183.814 207.269 184.803 205.928 185.443C201.212 187.692 197.073 184.788 196.687 178.96C196.301 173.133 199.814 166.579 204.529 164.329C209.245 162.08 213.384 164.984 213.77 170.812C213.876 172.49 213.672 174.222 213.2 175.9Z" fill="#EEF0F9" />
        </g>
        <path d="M201.954 172.778L239.809 148.746L222.504 129.163L184.649 153.196L201.954 172.778Z" fill="#E1E3ED" />
        <g filter="url(#filter0_i_2450_14737)">
          <path fillRule="evenodd" clipRule="evenodd" d="M266.821 204.745L256.746 194.512C244.431 182.142 224.392 182.142 212.133 194.512C199.819 206.882 199.819 227.011 212.133 239.325L222.097 249.333C209.278 255.743 194.781 259.285 179.499 259.285C125.763 259.285 82.1582 215.485 82.1582 161.507C82.1582 107.53 125.763 63.7296 179.499 63.7296C233.236 63.7296 276.841 107.53 276.841 161.507C276.785 177.026 273.258 191.701 266.821 204.745Z" fill="#D8DBEA" fillOpacity="0.01" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M338.339 275.927L313.558 251.108C330.714 225.503 340.75 194.62 340.75 161.378C340.75 72.2665 268.593 9.98848e-07 179.617 6.43588e-07C90.6404 2.88327e-07 18.4277 72.2665 18.4277 161.378C18.4277 250.49 90.5843 322.756 179.561 322.756C212.583 322.756 243.195 312.818 268.817 295.804L293.711 320.735C306.045 333.088 326.117 333.088 338.395 320.735C350.617 308.382 350.617 288.28 338.339 275.927ZM266.967 204.558L256.875 194.339C244.541 181.986 224.469 181.986 212.191 194.339C199.856 206.692 199.856 226.794 212.191 239.091L222.171 249.086C209.332 255.488 194.811 259.025 179.505 259.025C125.682 259.025 82.0063 215.283 82.0063 161.378C82.0063 107.473 125.682 63.7315 179.505 63.7315C233.328 63.7315 277.003 107.473 277.003 161.378C276.947 176.876 273.415 191.531 266.967 204.558Z" fill="#F1F5F6" />
        <g filter="url(#filter1_d_2450_14737)">
          <path d="M103.716 133.697L103.225 132.276C102.877 131.272 102.505 130.226 101.779 129.515C101.054 128.804 99.8613 128.56 99.1375 129.273C100.379 127.353 100.702 124.736 99.9706 122.514C99.2392 120.292 97.4754 118.528 95.4145 117.96C93.1629 117.338 90.8039 118.087 88.6126 118.935C86.3898 119.796 84.1639 120.789 82.3243 122.425C80.4849 124.061 79.057 126.439 78.9247 129.058C73.5943 126.938 67.7948 126.301 62.1959 127.222C60.5035 127.501 58.7876 127.938 57.3548 128.98C55.922 130.023 54.8089 131.775 54.8204 133.682L54.9777 133.696H103.716V133.697Z" fill="white" />
        </g>
        <g filter="url(#filter2_d_2450_14737)">
          <path d="M315.265 120.72C315.29 119.643 315.105 118.581 314.629 117.685C313.776 116.078 312.21 115.299 310.701 115.221C309.192 115.144 307.707 115.668 306.252 116.188C308.736 113.602 310.12 109.357 309.814 105.26C309.507 101.163 307.521 97.3364 304.698 95.4092C301.723 93.3775 298.123 93.4747 294.842 94.4449C287.154 96.7175 280.392 103.71 277.006 112.89C272.096 107.452 265.27 105.008 258.888 106.405C252.505 107.802 246.687 113.012 243.446 120.234L243.42 120.72H315.265V120.72Z" fill="white" />
        </g>
        <path d="M188.669 64.4139C166.882 66.2582 158.875 84.6992 165.434 95.4486C170.063 103.033 177.907 113.283 192.528 110.824C207.148 108.365 208.38 94.8342 200.68 92.9937C192.98 91.1539 165.549 99.7376 168.649 119.568C170.887 133.876 190.284 138 190.284 138" stroke="#BDBDFF" strokeWidth="1.12852" strokeDasharray="5.27 5.27" />
        <path fillRule="evenodd" clipRule="evenodd" d="M201.258 53.0945C201.258 53.0945 204.38 44.9394 204.709 41.767C204.883 40.1093 202.135 35.494 198.92 39.7632C195.705 44.0324 197.172 49.267 201.258 53.0945Z" fill="#108AE2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M190.18 44.2113C186.523 42.9745 184.868 43.9388 184.162 45.8635C183.456 47.7889 184.442 51.4538 189.958 52.2892C195.474 53.1246 200.943 53.0926 200.943 53.0926C200.943 53.0926 193.838 45.4481 190.18 44.2113Z" fill="#108AE2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M200.64 54.7096C200.723 54.3687 200.833 54.042 200.955 53.7227C200.28 53.8293 199.593 53.9791 198.902 54.182C198.152 54.4014 197.452 54.6893 196.793 55.0191C196.507 55.5591 196.276 56.1361 196.126 56.7556C195.199 60.5696 197.387 64.4137 201.063 65.6725C201.461 65.5936 201.861 65.5024 202.261 65.3853C203.381 65.0573 204.414 64.6233 205.347 64.1203C201.757 62.5453 199.691 58.6148 200.64 54.7096Z" fill="#CECEF3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M195.392 56.6998C195.48 56.3231 195.599 55.9628 195.732 55.6107C192.551 57.6481 190.77 60.8102 191.064 62.6541C189.032 63.5276 187.737 64.2634 187.737 64.2634C187.737 64.2634 189.271 64.1467 191.505 63.7172C192.702 65.466 196.019 66.637 199.687 66.2171C196.364 64.4987 194.489 60.5882 195.392 56.6998Z" fill="#CECEF3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M205.91 58.081C205.068 57.8788 204.546 57.0325 204.749 56.185C204.951 55.3432 205.802 54.8216 206.644 55.0238C207.49 55.2272 208.007 56.0767 207.806 56.9191C207.602 57.766 206.757 58.2838 205.91 58.081ZM209.605 56.948C208.927 54.5996 205.809 53.3868 202.132 53.8021C201.978 54.166 201.847 54.5499 201.754 54.9475C200.935 58.4332 202.827 61.9395 206.035 63.1557C208.711 61.4528 210.215 59.0416 209.605 56.948Z" fill="#CECEF3" />
        <defs>
          <filter id="filter0_i_2450_14737" x="82.1582" y="63.7296" width="194.683" height="195.556" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2450_14737" />
          </filter>
          <filter id="filter1_d_2450_14737" x="39.7734" y="100.427" width="94.0372" height="61.1067" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="7.52345" dy="5.26642" />
            <feGaussianBlur stdDeviation="11.2852" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.110459 0 0 0 0 0.0661979 0 0 0 0 0.3875 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2450_14737" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2450_14737" result="shape" />
          </filter>
          <filter id="filter2_d_2450_14737" x="228.373" y="76.4734" width="116.988" height="72.0834" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="7.52345" dy="5.26642" />
            <feGaussianBlur stdDeviation="11.2852" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.110459 0 0 0 0 0.0661979 0 0 0 0 0.3875 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2450_14737" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2450_14737" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_2450_14737" x1="123.859" y1="211.354" x2="133.727" y2="255.604" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C4CDD1" />
            <stop offset="1" stopColor="#C6CFD3" stopOpacity="0.01" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default EmptyStateIcon;

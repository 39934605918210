import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import http from '../services/Http';
import { exportToCsv } from '../utility';
import { EXPORT_KEYS_INSIGHTS } from '../constants/insights';
import {
  toastSuccess,
} from '../utility/toast';
import { INACTIVE_CAMPAIGN } from '../constants/campaign';
import { TITLES } from '../views/Insights/constants';
import { actionCreator } from '../utility/action';
import { ACTION_TYPES } from '../constants';
import { DATE_FORMAT_PRIMARY } from '../constants/formats';

const initialState = {
  data: {},
  charts: [],
  chartsList: [],
  segments: [],
  customers: [],
  job: {},
  detailsPageSegmentsLoading: false,
  detailsPageLoading: false,
  segmentsTable: {
    data: [],
    loading: false,
    total: 0,
    page: 1,
    order_by: 'composition',
    order_dir: 'DESC',
  },
};

export const getCustomers = createAsyncThunk(
  'insights/customers',
  async (partner_id) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
        is_active: INACTIVE_CAMPAIGN,
      },
    });

    return data;
  },
);

export const getInsights = createAsyncThunk(
  'insights',
  async ({ filters, page, perPage }) => {
    if (filters.customer_id) {
      const { data } = await http.get('/insights', {
        params: {
          ...filters,
          page,
          perPage,
        },
      });

      return data;
    }

    return {};
  },
);

export const deleteInsights = createAsyncThunk(
  'insights/delete',
  async ({ refreshInsights, id }) => {
    const { data } = await http.delete(`/insights/${id}`);
    refreshInsights();
    return data;
  },
);

export const getInsightsJob = actionCreator({
  id: 'insights/job',
  route: '/insights/job',
  actionType: ACTION_TYPES.get,
});

export const createInsight = actionCreator({
  id: 'insights/create',
  route: 'insights',
  actionType: ACTION_TYPES.post,
});

export const changeIsinternal = createAsyncThunk(
  'insights/create',
  async ({ refreshInsights, id }) => {
    const { data } = await http.put(`/insights/is_internal/${id}`);
    refreshInsights();
    return data;
  },
);

export const sendGetSimilarRequest = createAsyncThunk(
  'insights/send-get-similar-request',
  async (params) => {
    const { data } = await http.post('/insights/send-get-similar-request', params);
    toastSuccess('Successfully Sent!');
    return data;
  },
);

export const getInsightsCharts = createAsyncThunk(
  'insights/charts',
  async (params) => {
    const { data } = await http.get('/insights/charts', { params });

    return data;
  },
);

export const getInsightsChartsList = createAsyncThunk(
  'insights/charts/list',
  async ({ filters, ...params }) => {
    const { data } = await http.get('/insights/charts/list', {
      params: {
        ...params,
        ...filters,
      },
    });

    return data;
  },
);

export const getInsightsChartsCsv = createAsyncThunk(
  'insights/charts/csv',
  async (params, { getState }) => {
    const state = getState();
    const { data } = await http.get('/insights/charts', { params: { ...params, isExport: true } });
    const job = state.insights.job;
    const impressionSegment = state.homeData.impressionSegment;
    const conversionSegment = state.homeData.conversionSegment;

    const resData = data.map((item) => ({
      ...item,
      composition: `${item.composition}%`,
      type: TITLES[item.type],
      persons: '',
      households: '',
      report_id: '',
      creation_log: '',
      date_range: '',
      audience: '',
    }));

    const startDateUTC = moment.utc(job.query_start_date);
    const endDateUTC = moment.utc(job.query_end_date || job.lambda_end_date0);
    const dateRange = `${startDateUTC.format(DATE_FORMAT_PRIMARY)} - ${endDateUTC.format(DATE_FORMAT_PRIMARY)}`;

    const impSegment = job.imperssion_category_id
      ? job.imperssion_category_id?.split(',').map((id) => {
        return impressionSegment?.find((item) => item.patv_id === +id)?.patv_label;
      })
      : [];
    const conSegment = job.action_category_id
      ? job.action_category_id?.split(',').map((id) => {
        return conversionSegment?.find((item) => item.patv_id === +id)?.patv_label;
      })
      : [];
    const audience = `${[...impSegment, ...conSegment].toString(' ')}`;

    resData.unshift({
      chart_name: '',
      label: '',
      segment_id: '',
      segment_full_name: '',
      total: '',
      expected: '',
      composition: '',
      index_value: '',
      type: '',
      persons: job.dis_1,
      households: job.dis_0,
      report_id: job.id,
      creation_log: moment(job.create_date).format(DATE_FORMAT_PRIMARY),
      date_range: dateRange,
      audience,
    });
    exportToCsv({ name: `${job.partner_name}_${job.customerName}_${job.report_name}_${job.id}.csv`, data: resData, keys: EXPORT_KEYS_INSIGHTS });
    return data;
  },
);

export const getInsightsSegments = createAsyncThunk(
  'insights/segments',
  async (params) => {
    const { data } = await http.get('/insights/segments', { params });

    return data;
  },
);

export const insightsSlice = createSlice({
  name: 'insights',
  extraReducers: {
    [getInsights.pending]: (state) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload;
    },
    [getInsightsJob.pending]: (state) => {
      state.detailsPageLoading = true;
    },
    [getInsightsJob.fulfilled]: (state, { payload }) => {
      state.detailsPageLoading = false;
      state.job = payload.data;
    },
    [getInsightsJob.rejected]: (state) => {
      state.detailsPageLoading = false;
    },
    [getInsightsChartsList.pending]: (state) => {
      state.segmentsTable.loading = true;
    },
    [getInsightsChartsList.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.segmentsTable = {
        ...state.segmentsTable,
        loading: false,
        data: payload.data,
        total: payload.pagination.total || state.segmentsTable.total,
        order_by: arg.filters.order_by,
        order_dir: arg.filters.order_dir,
      };
    },
    [getInsights.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getInsightsChartsCsv.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsChartsCsv.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInsightsSegments.pending]: (state) => {
      state.loading = true;
    },
    [getInsightsSegments.fulfilled]: (state, { payload }) => {
      state.segments = payload;
      state.loading = false;
    },
    [getInsightsCharts.pending]: (state) => {
      state.loading = true;
    },
    [createInsight.pending]: (state) => {
      state.loading = true;
    },
    [createInsight.rejected]: (state) => {
      state.loading = false;
    },
    [createInsight.fulfilled]: (state) => {
      state.loading = false;
    },
    [getInsightsCharts.fulfilled]: (state, { payload }) => {
      state.charts = payload;
      state.loading = false;
    },
  },
  reducers: {
    resetInsightsReducer: () => initialState,
    clearInsights: (state) => {
      state.data = [];
    },
  },
  initialState,
});

export const { resetInsightsReducer, clearInsights } = insightsSlice.actions;
export default insightsSlice.reducer;

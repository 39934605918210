import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { DEFAULT_ERROR } from '../constants/errors';
import { exportToExcel } from '../utility';
import { EXPORT_KEYS_SEGMENTS } from '../constants/segments';

const initialState = {
  table: {
    data: [],
    loading: false,
    headers: {},
    total: 0,
    page: 1,
  },
  isLoading: false,
  isDataLoaded: false,
  customers: [],
  segmentAudiences: [],
};

export const getSegmentsList = createAsyncThunk(
  'segments/list',
  async ({
    page, perPage, filters,
  }) => {
    if (!filters.partner || !filters.customer) return [];

    const { data } = await http.get('/segments/list', {
      params: {
        page,
        per_page: perPage,
        ...{
          ...filters,
        },
      },
    });
    return data;
  },
);

export const getCustomers = createAsyncThunk(
  'segments/customers',
  async (partner_id) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
      },
    });

    return data;
  },
);

export const changeSegmentStatus = createAsyncThunk(
  'segments/change-status',
  async ({ id, ...params }) => {
    const { data } = await http.post(`segments/status/${id}`, params);
    toast.success('Status has been changed!');

    return data;
  },
);

export const createSegment = createAsyncThunk(
  'segments/create',
  async ({ onClose, filters, ...body }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await http.post('segments', body);
      toast.success('Segment has been created!');
      onClose();

      dispatch(getSegmentsList({
        page: 1,
        perPage: 6,
        filters,
      }));

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const updateSegment = createAsyncThunk(
  'segments/update',
  async (body, { rejectWithValue }) => {
    try {
      await http.post('/segments/update', body);
      toast.success('The end date was updated successfully!');

      return true;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const getSegmentsCsv = createAsyncThunk(
  'segments/csv',
  async (params) => {
    const { data } = await http.get('/segments/csv', { params });

    exportToExcel({
      name: 'segments',
      data,
      keys: EXPORT_KEYS_SEGMENTS,
      columnWidths: [7, 7, 7, 7, 7, 7, 12, 12],
    });
    return data;
  },
);

export const getAudienceNamesByIds = createAsyncThunk(
  'segments/audience-names-by-id',
  async (params) => {
    const { data } = await http.get('/audiences/by-ids', { params });

    return data;
  },
);

export const segmentsSlice = createSlice({
  name: 'segments',
  reducers: {
    clearSegmentReducer: () => initialState,
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
    clearSegmentAudiences: (state) => {
      state.segmentAudiences = [];
    },
    setIsDataLoaded: (state, { payload }) => {
      state.isDataLoaded = payload;
    },
  },
  initialState,
  extraReducers: {
    [getSegmentsList.pending]: (state) => {
      state.table.loading = true;
    },
    [getSegmentsList.rejected]: (state) => {
      state.table.loading = false;
    },
    [getSegmentsList.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.table = {
        ...state.table,
        loading: false,
        page: arg.page || state.table.page,
        data: payload.res,
        total: payload.total,
      };
    },
    [createSegment.pending]: (state) => {
      state.isLoading = true;
    },
    [createSegment.rejected]: (state) => {
      state.isLoading = false;
    },
    [createSegment.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateSegment.pending]: (state) => {
      state.isLoading = true;
    },
    [updateSegment.rejected]: (state) => {
      state.isLoading = false;
    },
    [updateSegment.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.customers = payload;
    },
    [getAudienceNamesByIds.fulfilled]: (state, { payload }) => {
      state.segmentAudiences = payload;
    },
    [changeSegmentStatus.fulfilled]: (state, { meta: { arg } }) => {
      state.table.data = state.table.data.map((item) => {
        if (item.id === arg.id) return { ...item, is_visible: arg.is_visible };
        return item;
      });
    },
  },
});

export const {
  clearSegmentReducer, setCustomers, clearSegmentAudiences, setIsDataLoaded,
} = segmentsSlice.actions;
export default segmentsSlice.reducer;

export const EXPORT_KEYS_DEALS = [
  { title: 'Id', field: 'id' },
  { title: 'Deal Name', field: 'deal_name' },
  { title: 'Transaction Start', field: 'start_date' },
  { title: 'Transaction End', field: 'end_date' },
  { title: 'DSP', field: 'dsp_id' },
  { title: 'Ad Format', field: 'ad_format_id' },
  { title: 'Platforms', field: 'deal_platform_id' },
  { title: 'Browsers', field: 'deal_browser_id' },
  { title: 'Operations Systems', field: 'deal_operation_system_id' },
  { title: 'Geo', field: 'deal_country_id' },
  { title: 'Domains', field: 'domains' },
  { title: 'Last Modified', field: 'last_modified_date' },
  { title: 'Last Modified by', field: 'last_modified_by' },
  { title: 'Created', field: 'create_date' },
  { title: 'Created by', field: 'created_by' },
];

export const DEALS_TABLE_PARAMS = {
  page: 1,
  perPage: 4,
  order_by: 'id',
  order_dir: 'DESC',
};

export const DEALS_STATUS_DONE = 1;

export const PARTNER = 'partner';
export const CUSTOMER = 'customer';
export const CUSTOM = 'custom';
export const EXISTS = 'exists';

export const AMOUNT_OF_ADDITION = 3;

import { SvgIcon } from '@mui/material';
import React from 'react';

function SegmentIcon(props) {
  return (
    <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
      <path d="M3.99364 6.84607L8.00001 0.275635L12.0064 6.84607H3.99364ZM12.5796 16.0127C11.6259 16.0127 10.8165 15.6802 10.1515 15.0152C9.48642 14.3501 9.15389 13.5407 9.15389 12.5871C9.15389 11.6334 9.48642 10.8227 10.1515 10.1552C10.8165 9.48761 11.6259 9.15382 12.5796 9.15382C13.5333 9.15382 14.3439 9.48761 15.0115 10.1552C15.679 10.8227 16.0128 11.6334 16.0128 12.5871C16.0128 13.5407 15.679 14.3501 15.0115 15.0152C14.3439 15.6802 13.5333 16.0127 12.5796 16.0127ZM0.820557 15.5961V9.57049H6.84614V15.5961H0.820557Z" fill="#184A8C" />
    </SvgIcon>
  );
}

export default SegmentIcon;

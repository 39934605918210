import { SvgIcon } from '@mui/material';
import React from 'react';

function DownloadSvg({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '16px', ...sx }} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M11.0185 7.62893C11.2803 7.89076 11.2803 8.31398 11.0185 8.57581C10.7566 8.83764 10.3334 8.83764 10.0716 8.57581L8.53611 7.04032L8.53611 11.7278C8.53611 12.0981 8.23677 12.3975 7.86646 12.3975C7.49615 12.3975 7.19682 12.0981 7.19682 11.7278L7.19682 7.04032L5.66133 8.57581C5.3995 8.83764 4.97628 8.83764 4.71445 8.57581C4.45262 8.31398 4.45262 7.89076 4.71445 7.62893L6.91959 5.4238C7.17874 5.16465 7.51959 5.03407 7.86044 5.03273L7.86646 5.03139L7.87249 5.03273C8.21334 5.03407 8.55419 5.16465 8.81334 5.4238L11.0185 7.62893Z" fill="#108AE2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5625 7.03125C14.0447 7.03125 13.625 6.61152 13.625 6.09375L13.625 2.74554L2.375 2.74553L2.375 6.09375C2.375 6.61152 1.95527 7.03125 1.4375 7.03125C0.919733 7.03125 0.5 6.61152 0.5 6.09375L0.5 2.74553L0.500001 0.602677L2.375 0.602678L13.625 0.602679L15.5 0.602679L15.5 2.74554L15.5 6.09375C15.5 6.61152 15.0803 7.03125 14.5625 7.03125Z" fill="#108AE2" />
    </SvgIcon>
  );
}

export default DownloadSvg;

import { SvgIcon } from '@mui/material';
import React from 'react';

function StatusPendingIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '67px', ...sx }} width="67" height="66" viewBox="0 0 67 66" fill="none" {...props}>
      <g filter="url(#filter0_f_8_457)">
        <rect x="15" y="15.5" width="36" height="36" rx="18" fill="#FFAC2F" fillOpacity="0.12" />
      </g>
      <path d="M33 27C31.7144 27 30.4577 27.3812 29.3888 28.0954C28.3199 28.8097 27.4868 29.8248 26.9948 31.0126C26.5028 32.2003 26.3741 33.5072 26.6249 34.7681C26.8757 36.029 27.4948 37.1872 28.4038 38.0962C29.3128 39.0052 30.471 39.6243 31.7319 39.8751C32.9928 40.1259 34.2997 39.9972 35.4874 39.5052C36.6752 39.0132 37.6903 38.1801 38.4046 37.1112C39.1188 36.0423 39.5 34.7856 39.5 33.5C39.4981 31.7767 38.8127 30.1244 37.5941 28.9059C36.3756 27.6873 34.7233 27.0019 33 27ZM33 38.375C32.0358 38.375 31.0933 38.0891 30.2916 37.5534C29.4899 37.0177 28.8651 36.2564 28.4961 35.3656C28.1271 34.4748 28.0306 33.4946 28.2187 32.5489C28.4068 31.6033 28.8711 30.7346 29.5529 30.0529C30.2346 29.3711 31.1033 28.9068 32.0489 28.7187C32.9946 28.5306 33.9748 28.6271 34.8656 28.9961C35.7564 29.3651 36.5177 29.9899 37.0534 30.7916C37.5891 31.5933 37.875 32.5358 37.875 33.5C37.8736 34.7925 37.3595 36.0316 36.4456 36.9456C35.5316 37.8595 34.2925 38.3736 33 38.375Z" fill="#FFAC2F" />
      <path d="M32.1876 32.988L30.8876 33.8005C30.7971 33.8571 30.7187 33.9311 30.6568 34.018C30.5949 34.105 30.5507 34.2033 30.5268 34.3073C30.503 34.4113 30.4998 34.519 30.5175 34.6243C30.5353 34.7295 30.5736 34.8302 30.6303 34.9207C30.6869 35.0111 30.7608 35.0896 30.8478 35.1515C30.9347 35.2133 31.033 35.2575 31.1371 35.2814C31.2411 35.3053 31.3488 35.3085 31.454 35.2907C31.5593 35.273 31.66 35.2346 31.7504 35.178L33.3045 34.203C33.4604 34.1053 33.5889 33.9694 33.6778 33.8083C33.7667 33.6472 33.8131 33.4661 33.8126 33.2821V31.2097C33.8126 30.9942 33.727 30.7876 33.5746 30.6352C33.4222 30.4828 33.2156 30.3972 33.0001 30.3972C32.7846 30.3972 32.5779 30.4828 32.4255 30.6352C32.2732 30.7876 32.1876 30.9942 32.1876 31.2097V32.988Z" fill="#FFAC2F" />
      <defs>
        <filter id="filter0_f_8_457" x="0" y="0.5" width="66" height="66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_8_457" />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default StatusPendingIcon;

import { SvgIcon } from '@mui/material';
import React from 'react';

function EbookIcon(props) {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <g clipPath="url(#clip0_1_14403)">
        <path style={{ fill: 'white', stroke: 'none' }} d="M10.0261 4.86398H13.6174L9.61987 0.994873V4.48496C9.61987 4.69026 9.79862 4.86398 10.0261 4.86398Z" fill="white" />
        <path style={{ stroke: 'none' }} d="M10.0261 6.0484C9.13239 6.0484 8.40114 5.33775 8.40114 4.46918V0.394775H3.02239C2.46989 0.394775 2.01489 0.836958 2.01489 1.3739V14.4025C2.01489 14.9394 2.46989 15.3816 3.02239 15.3816H13.1949C13.7474 15.3816 14.2024 14.9394 14.2024 14.4025V6.03261H10.0261V6.0484Z" fill="#184A8C" />
      </g>
      <defs>
        <clipPath id="clip0_1_14403">
          <rect width="16.25" height="15.7923" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EbookIcon;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import toast from 'react-hot-toast';
import { DATE_TIME_FORMAT } from '../constants/formats';
import http from '../services/Http';
import { exportToCsv } from '../utility';
import { getPartners } from './home-data';
import { EXPORT_KEYS_PERFORMANCE } from '../constants/performance';
import { DEFAULT_ERROR } from '../constants/errors';
import { INACTIVE_CAMPAIGN } from '../constants/campaign';
import { numberFormat } from '../utility/format';

const initialState = {
  loading: false,
  isTableLoading: false,
  performanceLoading: false,
  data: [],
  dataForExport: [],
  partners: [],
  customers: [],
  impressionSegment: [],
  conversionSegment: [],
  audienceSite: [],
  conversionSite: [],
  audienceDevice: [],
  page: 1,
  total: 0,
  totals: {},
};

export const getAudienceSegment = createAsyncThunk(
  'performance/audience-segment',
  async (params) => {
    const { data } = await http.get('/performance/audience-segment', {
      params,
    });

    return data;
  },
);

export const getAudienceSite = createAsyncThunk(
  'performance/audience-site',
  async (params) => {
    const { data } = await http.get('/performance/audience-site', { params });

    return data;
  },
);

export const getConversionSite = createAsyncThunk(
  'performance/conversion-site',
  async (params) => {
    const { data } = await http.get('/performance/conversion-site', { params });

    return data;
  },
);

export const getAudienceDevice = createAsyncThunk(
  'performance/audience-device',
  async () => {
    const { data } = await http.get('/performance/audience-device');

    return data;
  },
);

export const getPerformance = createAsyncThunk(
  'performance',
  async ({ page, perPage, filters }, rejectWithValue) => {
    try {
      const { startDate, endDate, date } = filters;

      if (!filters.partner.length) return [];

      const { data } = await http.get('/performance', {
        params: {
          page,
          per_page: perPage,
          isPerformance: true,
          customers_active: 1,
          ...{
            ...filters,
          },
          start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
          end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
          date: startDate ? null : date,
        },
      });
      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const getPerformanceCsv = createAsyncThunk(
  'performance/csv',
  async ({ filters, columns }, { getState }) => {
    const state = getState();
    const totals = state.performance.totals;
    const { startDate, endDate, date } = filters;

    const { data } = await http.get('/performance', {
      params: {
        isExport: true,
        isPerformance: true,
        customers_active: 1,
        ...{
          ...filters,
        },
        start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
        end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
        date: startDate ? null : date,
        order_by: 'reportDate',
        order_dir: 'DESC',
      },
    });

    const keys = [
      ...EXPORT_KEYS_PERFORMANCE.filter((key) => columns.includes(key.field)),
      ...(filters.imperssion_category_id?.length ? [{ title: 'Impression Segment', field: 'patv_label_Imp' }] : []),
      ...(filters.action_category_id?.length ? [{ title: 'Conversion Segment', field: 'patv_label_Act' }] : []),
      ...(filters.impression_adserver_dtype?.length ? [{ title: 'Impression Device', field: 'dtype_label_Imp' }] : []),
      ...(filters.action_adserver_dtype?.length ? [{ title: 'Conversion Device', field: 'dtype_label_Act' }] : []),
      ...(filters.impression_site_id?.length ? [{ title: 'Impression Site', field: 'dpsite_imp_url' }] : []),
      ...(filters.action_site_id?.length ? [{ title: 'Conversion Site', field: 'dpsite_act_url' }] : []),
      ...(filters.match_level?.length ? [{ title: 'Unique Attributable Matches', field: 'uniqueSentActBy3' }] : []),
      ...(filters.revCPA?.length ? [{ title: 'CPA', field: 'revCpa' }] : []),
    ];

    const resData = data.data.map((item) => ({
      ...item,
      revCPA: numberFormat(item.revCpa || 0),

    }));

    exportToCsv({
      name: 'performance.csv',
      data: [
        {
          ...totals,
          reportDate: totals.diffReportDateCount,
          customerName: totals.totalCustomers,
          partnerName: totals.totalPartners,
          days_to_action: totals.avgDays,
          total_match1: totals.totalTotalMatchImp,
          total_match2: totals.matchAction,
          uniqueImpActBy1: totals.totalUniqueImpActBy1,
          utilize2Percent: totals.totalUtilizePercent2,
          patv_label_Imp: totals.imperssion_category_id,
          patv_label_Act: totals.action_category_id,
          dtype_label_Imp: totals.impression_adserver_dtype,
          dpsite_imp_url: totals.impression_site_id,
          dpsite_act_url: totals.action_site_id,
          dtype_label_Act: totals.action_adserver_dtype,
          revCPA: numberFormat(totals.revCpa || 0),
        },
        ...resData,
      ],
      keys,
    });

    return data;
  },
);

export const getCustomers = createAsyncThunk(
  'performance/customers',
  async (partner_id) => {
    const { data } = await http.get('dashboard/performance/customers', {
      params: {
        partner_id,
        is_active: INACTIVE_CAMPAIGN,
      },
    });

    return data;
  },
);

export const getPerformanceTotal = createAsyncThunk(
  '/reports/performance/total',
  async (params, { rejectWithValue }) => {
    try {
      const { startDate, endDate, date } = params;
      const { data } = await http.get('/performance/totals',
        {
          params: {
            ...params,
            isPerformance: true,
            customers_active: true,
            start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
            end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
            date: startDate ? null : date,
          },
        });

      return data;
    } catch (e) {
      console.log('##', e, e.startsWith('Error: Network Error'));
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const performanceSlice = createSlice({
  name: 'performance',
  reducers: {
    clearPerformanceReducer: () => initialState,
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },
  },
  extraReducers: {
    [getPerformanceTotal.pending]: (state) => {
      state.isTableLoading = true;
    },
    [getPerformanceCsv.pending]: (state) => {
      state.isTableLoading = true;
    },
    [getPerformanceCsv.fulfilled]: (state, { payload }) => {
      state.isTableLoading = false;
      state.dataForExport = payload;
    },
    [getPerformanceTotal.fulfilled]: (state, { payload }) => {
      state.isTableLoading = false;
      state.totals = payload?.[0];
    },
    [getAudienceDevice.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceDevice.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.audienceDevice = payload;
    },
    [getAudienceSite.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.audienceSite = payload;
    },
    [getConversionSite.pending]: (state) => {
      state.loading = true;
    },
    [getConversionSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.conversionSite = payload;
    },
    [getPartners.pending]: (state) => {
      state.loading = true;
    },
    [getPartners.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.partners = payload;
    },
    [getAudienceSegment.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceSegment.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg.attribute_type_id === 16 ? 'impressionSegment' : 'conversionSegment'] = payload;
      state.loading = false;
    },
    [getCustomers.pending]: (state) => {
      state.loading = true;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customers = payload;
    },
    [getPerformance.pending]: (state) => {
      state.performanceLoading = true;
    },
    [getPerformance.fulfilled]: (state, { payload }) => {
      state.performanceLoading = false;
      state.data = payload.data;
      state.total = payload.pagination?.total === undefined
        ? state?.total
        : payload.pagination?.total;
    },
  },
  initialState,
});

export const { clearPerformanceReducer, setCustomers } = performanceSlice.actions;
export default performanceSlice.reducer;

// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from '../services/Http';
import { getPartners } from './home-data';
import { getLastSelectByKey, setLastSelect } from '../utility/Utils';

// ** UseJWT import to get config
import useJwt from '../@core/auth/jwt/useJwt';

useJwt({});

const initialUser = () => {
  const item = window.localStorage.getItem('userData');
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialPartner = () => {
  const item = window.localStorage.getItem('partnerData');
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialRole = () => {
  const item = window.localStorage.getItem('role');
  //* * Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const getUser = createAsyncThunk(
  'auth/user',
  async () => {
    const { data } = await http.get('/auth/user');
    return data;
  },
);

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    user: null,
    partnerData: initialPartner(),
    role: initialRole(),
    loading: false,
    email: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    handleLogin: (state, action) => {
      const { role, partner, ...userData } = action.payload.userData;
      state.userData = userData;
      state.partnerData = partner;
      state.role = role;
      state.email = null;
      state.user = action.payload.userData;
      localStorage.setItem('accessToken', action.payload.accessToken);
      // localStorage.setItem('refreshToken', action.payload.refreshToken)
    },
    handleLogout: (state) => {
      state.userData = {};
      state.partnerData = {};
      // ** Remove user, partner, accessToken from localStorage
      localStorage.removeItem('userData');
      localStorage.removeItem('partnerData');
      localStorage.removeItem('role');
      localStorage.removeItem('accessToken');
    },
  },
  extraReducers: {
    [getUser.pending]: (state) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    },
    [getPartners.fulfilled]: (state, { payload }) => {
      const isHasPartner = payload.find((i) => i.partner_id === getLastSelectByKey('partner'));
      if (!isHasPartner) {
        setLastSelect({ value: '', key: 'partner' });
        setLastSelect({ value: '', key: 'customer' });
      }
    },
  },
});

export const {
  handleLogin, handleLogout, setEmail, setUser,
} = authSlice.actions;

export default authSlice.reducer;

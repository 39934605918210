import { SvgIcon } from '@mui/material';
import React from 'react';

function DealDraftIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '61px', ...sx }} width="62" height="61" viewBox="0 0 62 61" fill="none" {...props}>
      {/* <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M26.8515 37.0181C26.4848 37.0181 26.1709 36.8876 25.9098 36.6265C25.6487 36.3654 25.5182 36.0515 25.5182 35.6848V25.0181C25.5182 24.6515 25.6487 24.3376 25.9098 24.0765C26.1709 23.8154 26.4848 23.6848 26.8515 23.6848H32.1848L36.1848 27.6848V35.6848C36.1848 36.0515 36.0543 36.3654 35.7932 36.6265C35.532 36.8876 35.2182 37.0181 34.8515 37.0181H26.8515ZM31.5182 28.3515V25.0181H26.8515V35.6848H34.8515V28.3515H31.5182Z" fill="#797979" />
      <g filter="url(#filter0_f_11_572)">
        <rect x="15.8515" y="15.3516" width="30.297" height="30.297" rx="15.1485" fill="#797979" fillOpacity="0.12" />
      </g>
      <defs>
        <filter id="filter0_f_11_572" x="0.851501" y="0.351562" width="60.297" height="60.2971" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_11_572" />
        </filter>
      </defs>
      {/* </svg> */}
    </SvgIcon>
  );
}

export default DealDraftIcon;

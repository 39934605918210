export const CREATE_ACTIVATION = 'create';
export const CREATE_AND_SHARE_ACTIVATION = 'create_and_share';
export const EDIT_ACTIVATION = 'edit';
export const EDIT_AND_SHARE_ACTIVATION = 'edit_and_share';

export const EXPORT_KEYS_AUDIENCE = [
  { title: 'Name', field: 'name' },
  { title: 'Segments', field: 'segments' },
  { title: 'Activated', field: 'activated' },
  { title: 'IntentIQ_IDS', field: 'intentIQ_IDS' },
  { title: 'platforms', field: 'platforms' },
  { title: 'Last modified', field: 'last_modified' },
  { title: 'Last modified by', field: 'last_modified_by_email' },
];

export const COLUMN_WIDTH_AUDIENCES = [5, 12, 7, 5, 10, 8, 10];

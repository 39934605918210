import { daysAgoSinceStartDate, getMTD } from '../utility';

export const YESTERDAY = '-2';
export const BEGINNING_OF_MONTH = '1000';
export const PREVIOUS_MONTH = '1001';
export const LAST_2_DAYS = '-2';
export const LAST_3_DAYS = '-3';
export const LAST_4_DAYS = '-4';
export const LAST_5_DAYS = '-5';
export const LAST_6_DAYS = '-6';
export const LAST_7_DAYS = '-7';
export const LAST_14_DAYS = '-14';
export const LAST_21_DAYS = '-21';
export const LAST_28_DAYS = '-28';
export const BEGINNING_OF_TIME = '0';

export const MORE_THAN_SEVEN_DAYS_DATES_RANGE = [
  LAST_14_DAYS, LAST_21_DAYS, LAST_28_DAYS, BEGINNING_OF_TIME,
];

export const ANY_OPTION = { value: '', label: 'Any' };

const dateRangeOptionsBase = [
  { value: BEGINNING_OF_MONTH, label: 'Beginning of month' },
  { value: PREVIOUS_MONTH, label: 'Previous month' },
  { value: LAST_2_DAYS, label: 'Last 2 days' },
  { value: LAST_3_DAYS, label: 'Last 3 days' },
  { value: LAST_4_DAYS, label: 'Last 4 days' },
  { value: LAST_5_DAYS, label: 'Last 5 days' },
  { value: LAST_6_DAYS, label: 'Last 6 days' },
  { value: LAST_7_DAYS, label: 'Last 7 days' },
  { value: LAST_14_DAYS, label: 'Last 14 days' },
  { value: LAST_21_DAYS, label: 'Last 21 days' },
  { value: LAST_28_DAYS, label: 'Last 28 days' },
  { value: BEGINNING_OF_TIME, label: 'Beginning of time' },
];

export const dateRangeOptionsFileTracker = [
  { value: YESTERDAY, label: 'Today' },
  ...dateRangeOptionsBase,
];

export const dateRangeOptions = [
  { value: YESTERDAY, label: 'Yesterday' },
  ...dateRangeOptionsBase,
];

export const MatchLevel = {
  PROFILE: 'p',
  DEVICE: 'd',
  PERSON: 'n',
  HOUSE: 'h',
  STATISTICAL: 's',
};

export const TimeGrouping = {
  DAY: 'daily',
  WEEK: 'weekly',
  MONTH: 'monthly',
  SO_FAR: 'sf',
  LIFETIME: 'lifetime',
  HOUR: 'hour',
};

export const timeGroupingOptions = [
  { value: TimeGrouping.DAY, label: 'Daily' },
  { value: TimeGrouping.WEEK, label: 'Weekly' },
  { value: TimeGrouping.MONTH, label: 'Monthly' },
  { value: TimeGrouping.SO_FAR, label: 'So far' },
];

export const DEFAULT_PAGE_SIZE = 6;

export const tableSizeOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const CAMPAIGN_STATUSES = [
  'low',
  'no_data',
  'pending',
  'warning',
  'success',
];

export const FILTER_ALL = 'ALL';
export const SUPER_ADMIN = 'Super admin';

export const ACTION_TYPES = {
  delete: 'delete',
  get: 'get',
  post: 'post',
  put: 'put',
};

export const DESC = 'desc';
export const ASC = 'asc';
export const ID = 'id';

export const PARTNER_KEY = 'partner';
export const CUSTOMER_KEY = 'customer';
export const CUSTOMERS_KEY = 'customers';
export const PERFORMANCE_CUSTOMERS = 'performance_customers';
export const PERFORMANCE_PARTNER = 'performance_partner';

export const MONTHS = 'months';
export const DEFAULT_DATE = '1900-01-01'
export const SELECTION = 'selection'
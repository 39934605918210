import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../constants/formats';
import { EXPORT_KEYS_RAW_REPORT } from '../constants/rawReport';
import http from '../services/Http';
import { exportToCsv } from '../utility';
import { getPartners } from './home-data';
import { INACTIVE_CAMPAIGN } from '../constants/campaign';

const initialState = {
  loading: false,
  isTotalLoading: false,
  isTableLoading: false,
  isSvgLoading: false,
  data: [],
  partners: [],
  customers: [],
  impressionSegment: [],
  conversionSegment: [],
  audienceSite: [],
  conversionSite: [],
  audienceDevice: [],
  page: 1,
  total: 0,
  totals: {},
};

export const getRawReport = createAsyncThunk(
  'raw-report',
  async ({ filters, ...params }) => {
    const { startDate, endDate, date } = filters;

    const { data } = await http.get('/raw-report', {
      params: {
        ...params,
        ...filters,
        start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
        end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
        date: startDate ? null : date,
      },
    });

    return data;
  },
);

export const getRawReportCsv = createAsyncThunk(
  'raw-report/csv',
  async ({ filters, columns, ...params }, { getState }) => {
    const { startDate, endDate, date } = filters;
    const state = getState();
    const totals = state.rawReport.totals;

    const { data } = await http.get('/raw-report/csv', {
      params: {
        ...params,
        ...filters,
        start_date: startDate && moment(startDate).format(DATE_TIME_FORMAT),
        end_date: endDate && moment(endDate).format(DATE_TIME_FORMAT),
        date: startDate ? null : date,
      },
    });

    const keys = [
      ...EXPORT_KEYS_RAW_REPORT.filter((key) => columns.includes(key.field)),
      ...(filters.imperssion_category_id?.length ? [{ title: 'Impression Segment', field: 'patv_label_Imp' }] : []),
      ...(filters.action_category_id?.length ? [{ title: 'Conversion Segment', field: 'patv_label_Act' }] : []),
      ...(filters.impression_adserver_dtype?.length ? [{ title: 'Impression Device', field: 'dtype_label_Imp' }] : []),
      ...(filters.action_adserver_dtype?.length ? [{ title: 'Conversion Device', field: 'dtype_label_Act' }] : []),
      ...(filters.impression_site_id?.length ? [{ title: 'Impression Site', field: 'dpsite_imp_url' }] : []),
      ...(filters.action_site_id?.length ? [{ title: 'Conversion Site', field: 'dpsite_act_url' }] : []),
    ];

    exportToCsv({
      data: [{
        ...totals,
        partner_customer_id: totals.totalCustomers,
        partner_name: totals.totalPartners,
      }, ...data],
      name: 'rawReport.csv',
      keys,
    });
    return data;
  },
);

export const getAudienceSegment = createAsyncThunk(
  'raw-report/audience-segment',
  async (params) => {
    const { data } = await http.get('/performance/audience-segment', {
      params,
    });

    return data;
  },
);

export const getAudienceSite = createAsyncThunk(
  'raw-report/audience-site',
  async (params) => {
    const { data } = await http.get('/performance/audience-site', { params });

    return data;
  },
);

export const getConversionSite = createAsyncThunk(
  'raw-report/conversion-site',
  async (params) => {
    const { data } = await http.get('/performance/conversion-site', { params });

    return data;
  },
);

export const getAudienceDevice = createAsyncThunk(
  'raw-report/audience-device',
  async () => {
    const { data } = await http.get('/performance/audience-device');

    return data;
  },
);

export const getCustomers = createAsyncThunk(
  'raw-report/customers',
  async (partner_id) => {
    const { data } = await http.get('/dashboard/performance/customers', {
      params: {
        partner_id,
        is_active: INACTIVE_CAMPAIGN,
      },
    });

    return data;
  },
);

export const getPerformanceTotal = createAsyncThunk(
  '/raw-report/total',
  async (params) => {
    const { data } = await http.get('/dashboard/performance/totals', { params });

    return data;
  },
);

export const rawReportSlice = createSlice({
  name: 'rawReport',
  reducers: {
    clearRawReportReducer: () => initialState,
    setRawReportCustomers: (state, { payload }) => {
      state.customers = payload;
    },
  },
  extraReducers: {
    [getPerformanceTotal.pending]: (state) => {
      state.loading = true;
    },
    [getPerformanceTotal.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.totals = payload?.[0];
    },
    [getRawReportCsv.pending]: (state) => {
      state.isSvgLoading = true;
    },
    [getRawReportCsv.fulfilled]: (state) => {
      state.isSvgLoading = false;
    },
    [getRawReportCsv.rejected]: (state) => {
      state.isSvgLoading = false;
    },
    [getAudienceDevice.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceDevice.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.audienceDevice = payload;
    },
    [getAudienceSite.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.audienceSite = payload;
    },
    [getConversionSite.pending]: (state) => {
      state.loading = true;
    },
    [getConversionSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.conversionSite = payload;
    },
    [getPartners.pending]: (state) => {
      state.loading = true;
    },
    [getPartners.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.partners = payload;
    },
    [getAudienceSegment.pending]: (state) => {
      state.loading = true;
    },
    [getAudienceSegment.fulfilled]: (state, { payload, meta: { arg } }) => {
      state[arg.attribute_type_id === 16 ? 'impressionSegment' : 'conversionSegment'] = payload;
      state.loading = false;
    },
    [getCustomers.pending]: (state) => {
      state.isTableLoading = true;
    },
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.isTableLoading = false;
      state.customers = payload;
    },
    [getRawReport.pending]: (state, { meta: { arg } }) => {
      if (arg?.filters?.isTotal) {
        state.isTotalLoading = true;
      } else {
        state.loading = true;
      }
    },
    [getRawReport.fulfilled]: (state, { payload, meta: { arg } }) => {
      if (arg?.filters?.isTotal) {
        state.totals = payload.data[0];
        state.isTotalLoading = false;
      } else {
        state.data = payload.data;
        state.total = payload.pagination?.total === undefined
          ? state.total
          : payload.pagination?.total;
      }
      state.loading = false;
    },
  },
  initialState,
});

export const { clearRawReportReducer, setRawReportCustomers } = rawReportSlice.actions;
export default rawReportSlice.reducer;

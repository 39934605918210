import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import http from '../services/Http';
import { exportToExcel, getTimeZoneDate } from '../utility';
import { DEFAULT_ERROR } from '../constants/errors';
import { FIRST_PARTY_SEGMENT, THIRD_PARTY_SEGMENT } from '../constants/segments';
import { COLUMN_WIDTH_AUDIENCES, EXPORT_KEYS_AUDIENCE } from '../constants/audiences';
import { DATE_FORMAT_SLASH, TIME_FORMAT } from '../constants/formats';

const initialState = {
  loading: false,
  audiences: [],
  platforms: [],
  isAudienceLoading: false,
  newAudience: {},
  firstPartySegments: [],
  thirdPartySegments: [],
  page: 1,
  total: 0,
};

export const getAudiences = createAsyncThunk(
  '/audience',
  async (params) => {
    const { data } = await http.get('/audiences', { params });

    return data;
  },
);

export const createSegment = createAsyncThunk(
  'audience/segment/create',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await http.post('segments', body);
      toast.success('Segment has been created!');

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const getAudineceCsv = createAsyncThunk(
  '/audience/csv',
  async (params) => {
    const { data } = await http.get('/audiences', { params: { ...params, isExport: true } });
    const exportData = data.data.map((i) => {
      return {
        name: i.name,
        segments: i.audienceSegments?.map((i) => i.segment?.name)?.join(', '),
        activated: i.platformsAudiences?.some(((item) => item.status === 1))
          ? 'Pending' : (i.platformsAudiences?.length > 0
          && i.platformsAudiences?.every(((item) => item.status === 2)))
            ? 'Activated' : 'Non activated',
        intentIQ_IDS: i.users_amount,
        platforms: i?.platformsAudiences.map((i) => i.platform.name)?.join(', '),
        last_modified: `${getTimeZoneDate(i.last_modified, DATE_FORMAT_SLASH)} ${getTimeZoneDate(i.last_modified, TIME_FORMAT)}`,
        last_modified_by_email: i.modified_by_user?.email,
      };
    });

    exportToExcel({
      name: 'audiences',
      data: exportData,
      keys: EXPORT_KEYS_AUDIENCE,
      columnWidths: COLUMN_WIDTH_AUDIENCES,
    });

    return data;
  },
);

export const getCustomers = createAsyncThunk(
  '/campaign/tags',
  async (params) => {
    const { data } = await http.get('/audience', { params });

    return data;
  },
);

export const createAudience = createAsyncThunk(
  '/audience/create',
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
      const res = await http.post('/audiences', data);
      toast.success('Audience created');
      dispatch(getAudiences({
        search: data.search,
        partner_id: data.partner_id,
        customer_id: data.customer_id,
        page: 1,
        perPage: 6,
      }));

      callback(res.data);

      return res.data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const deleteAudience = createAsyncThunk(
  '/audience/delete',
  async ({ id, filters }, { dispatch }) => {
    const { data } = await http.delete(`/audiences/${id}`);
    toast.success('Audience deleted');
    dispatch(getAudiences({
      search: filters.search,
      partner_id: filters.partner,
      customer_id: filters.customer,
      page: 1,
      perPage: 6,
    }));
    return { data, id };
  },
);

export const activateAudience = createAsyncThunk(
  '/audience/activate',
  async ({ data, callback, filters }, { dispatch, rejectWithValue }) => {
    try {
      await http.post('/audiences/activate', data);
      callback();
      dispatch(getAudiences({
        search: filters.search,
        partner_id: filters.partner,
        customer_id: filters.customer,
        page: 1,
        perPage: 6,
      }));
      toast.success('Audience sent to activation');

      return data;
    } catch (e) {
      const errorMessage = e.response?.data?.message;

      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error(DEFAULT_ERROR);
      }
      return rejectWithValue(null);
    }
  },
);

export const editAudienceActivation = createAsyncThunk(
  '/audience/edit/activate',
  async ({ data, callback, filters }, { dispatch }) => {
    await http.post('/audiences/edit/activate', data);
    callback();
    dispatch(getAudiences({
      search: filters.search,
      partner_id: filters.partner,
      customer_id: filters.customer,
      page: 1,
      perPage: 6,
    }));

    toast.success('Audience sent to activation');
    return data;
  },
);

export const updateAudience = createAsyncThunk(
  '/audience/update',
  async ({ data, id, callback }, { dispatch }) => {
    const res = await http.put(`/audiences/${id}`, data);
    callback();
    dispatch(getAudiences({
      search: data.search,
      partner_id: res.data.partner_id,
      customer_id: res.data.customer_id,
      page: 1,
      perPage: 6,
    }));
    toast.success('Audience updated');
    return res.data;
  },
);

export const getPlatforms = createAsyncThunk(
  '/audience/platforms',
  async () => {
    const { data } = await http.get('/audiences/platforms');
    return data;
  },
);

export const getSegments = createAsyncThunk(
  '/audience/segments',
  async (params) => {
    const { data } = await http.get('/segments', { params: { ...params, is_visible: 1 } });
    return data;
  },
);

export const audienceSlice = createSlice({
  name: 'audience',
  reducers: {
    clearCampaignReducer: () => initialState,
    clearCampaignTags: (state) => {
      state.tags = [];
    },
    insertSegment: (state, { payload }) => {
      state.firstPartySegments = [...state.firstPartySegments, payload];
    },
  },
  initialState,
  extraReducers: {
    [getAudiences.pending]: (state) => {
      state.loading = true;
    },
    [getAudiences.rejected]: (state) => {
      state.loading = false;
    },
    [getAudiences.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.audiences = payload.data;
      state.page = payload.page;
      state.total = payload.total;
    },
    [getPlatforms.fulfilled]: (state, { payload }) => {
      state.platforms = payload;
    },
    [getSegments.fulfilled]: (state, { payload, meta: { arg: { type } } }) => {
      if (type === FIRST_PARTY_SEGMENT) {
        state.firstPartySegments = payload;
      } else if (type === THIRD_PARTY_SEGMENT) {
        state.thirdPartySegments = payload;
      } else {
        state.segments = payload;
      }
    },
    [deleteAudience.pending]: (state) => {
      state.isAudienceLoading = true;
    },
    [deleteAudience.fulfilled]: (state) => {
      state.isAudienceLoading = false;
    },
    [deleteAudience.rejected]: (state) => {
      state.isAudienceLoading = false;
    },
    [createAudience.pending]: (state) => {
      state.isAudienceLoading = true;
    },
    [createAudience.rejected]: (state) => {
      state.isAudienceLoading = false;
    },
    [createAudience.fulfilled]: (state, { payload }) => {
      state.isAudienceLoading = false;
      state.newAudience = payload;
    },
    [activateAudience.pending]: (state) => {
      state.isAudienceLoading = true;
    },
    [activateAudience.rejected]: (state) => {
      state.isAudienceLoading = false;
    },
    [activateAudience.fulfilled]: (state) => {
      state.isAudienceLoading = false;
    },
    [updateAudience.pending]: (state) => {
      state.isAudienceLoading = true;
    },
    [updateAudience.rejected]: (state) => {
      state.isAudienceLoading = false;
    },
    [updateAudience.fulfilled]: (state, { payload }) => {
      const index = state.audiences.findIndex((item) => item.id === payload.id);
      state.audiences[index] = payload;
      state.isAudienceLoading = false;
    },
  },
});

export const { insertSegment } = audienceSlice.actions;
export default audienceSlice.reducer;

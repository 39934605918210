import { SvgIcon } from '@mui/material';
import React from 'react';

function DealIcon({ sx, ...props }) {
  return (
    <SvgIcon sx={{ fontSize: '13px', ...sx }} width="18" height="15" viewBox="0 0 18 15" fill="none" {...props}>
      <path d="M9.00002 5.32373L6.37985 2.70356L9.00002 0.083374L11.6282 2.70356L9.00002 5.32373ZM0.258057 14.4103V11.2853C0.258057 10.8825 0.394543 10.5414 0.667515 10.262C0.940473 9.98267 1.2714 9.83764 1.66029 9.82696H4.22918C4.48025 9.82696 4.71877 9.88839 4.94472 10.0113C5.17068 10.1341 5.35471 10.3056 5.49681 10.5257C5.91027 11.094 6.42282 11.5364 7.03447 11.8526C7.64611 12.1688 8.30129 12.327 9.00002 12.327C9.70728 12.327 10.3681 12.1688 10.9824 11.8526C11.5967 11.5364 12.1063 11.094 12.5112 10.5257C12.6704 10.3056 12.8609 10.1341 13.0826 10.0113C13.3042 9.88839 13.5337 9.82696 13.7709 9.82696H16.3397C16.7372 9.83764 17.0716 9.98267 17.3429 10.262C17.6143 10.5414 17.75 10.8825 17.75 11.2853V14.4103H12.3333V12.4503C11.8579 12.8136 11.3376 13.0921 10.7725 13.2861C10.2073 13.48 9.61647 13.5769 9.00002 13.5769C8.39745 13.5769 7.81278 13.4792 7.24602 13.2836C6.67924 13.0881 6.15547 12.8088 5.6747 12.4455V14.4103H0.258057ZM2.57377 8.67308C1.94343 8.67308 1.40391 8.44873 0.955202 8.00002C0.50648 7.5513 0.282119 7.01177 0.282119 6.38144C0.282119 5.73722 0.506473 5.19422 0.955182 4.75244C1.4039 4.31067 1.94343 4.08979 2.57377 4.08979C3.218 4.08979 3.761 4.31067 4.20276 4.75244C4.64454 5.19422 4.86543 5.73722 4.86543 6.38144C4.86543 7.01177 4.64454 7.5513 4.20276 8.00002C3.761 8.44873 3.218 8.67308 2.57377 8.67308ZM15.4263 8.67308C14.796 8.67308 14.2564 8.44873 13.8077 8.00002C13.359 7.5513 13.1346 7.01177 13.1346 6.38144C13.1346 5.73722 13.359 5.19422 13.8077 4.75244C14.2564 4.31067 14.796 4.08979 15.4263 4.08979C16.0705 4.08979 16.6135 4.31067 17.0553 4.75244C17.4971 5.19422 17.718 5.73722 17.718 6.38144C17.718 7.01177 17.4971 7.5513 17.0553 8.00002C16.6135 8.44873 16.0705 8.67308 15.4263 8.67308Z" fill="#184A8C" />
    </SvgIcon>
  );
}

export default DealIcon;

import { SvgIcon } from '@mui/material';
import React from 'react';

function RawReportIcon(props) {
  return (
    <SvgIcon width="13" height="15" viewBox="0 0 13 15" fill="none" {...props}>
      <path d="M0.583252 14.2916V0.75H7.33L7.66333 2.41667H12.2499V9.91663H7.16979L6.83646 8.24996H1.83323V14.2916H0.583252Z" fill="#615E8B" />
    </SvgIcon>
  );
}

export default RawReportIcon;

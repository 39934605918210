import {
  CUSTOMER_KEY, CUSTOMERS_KEY, PARTNER_KEY, PERFORMANCE_CUSTOMERS,
  PERFORMANCE_PARTNER,
} from '../constants';
import { DEFAULT_ROUTE_IIQ, DEFAULT_ROUTE_DATONICS } from '../constants/routes';
import { SKINS, skin } from '../constants/skin';
import { rolesPages } from '../navigation/vertical';

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export const getDefaultRoute = (skin, roles) => {
  if (skin === SKINS.DATONICS && roles?.includes('insights_datonics.can_read')) return DEFAULT_ROUTE_DATONICS;
  if (skin === SKINS.IIQ && roles?.includes('dashboard.can_read')) return DEFAULT_ROUTE_IIQ;

  const defaultRoute = rolesPages[roles?.find((role) => rolesPages[role])];

  if (defaultRoute) return defaultRoute;

  return skin !== SKINS.IIQ ? DEFAULT_ROUTE_DATONICS : DEFAULT_ROUTE_IIQ;
};

export const getHomeRouteForLoggedInUser = (roles) => {
  if (roles) return getDefaultRoute(skin, roles);
  return '/login';
};

function upsert(array, element) {
  const i = array.findIndex((_element) => _element.key === element.key);
  if (i > -1) {
    if (!element.value && element.value !== null) {
      array.splice(i, 1);
    } else { array[i] = element; }
  } else { array.push(element); }
}

export const getLastSelect = () => JSON.parse(localStorage.getItem('lastSelected') || null);

export const setLastSelect = (selected) => {
  const saved = getLastSelect() || [];
  upsert(saved, selected);
  localStorage.setItem('lastSelected', JSON.stringify(saved));
};

export const clearLastSelect = () => {
  setLastSelect({ key: PARTNER_KEY, value: '' });
  setLastSelect({ key: CUSTOMER_KEY, value: '' });
  setLastSelect({ key: CUSTOMERS_KEY, value: [] });
  setLastSelect({ key: PERFORMANCE_CUSTOMERS, value: [] });
  setLastSelect({ key: PERFORMANCE_PARTNER, value: [] });
};

export const setLastSelectPartner = (value) => {
  setLastSelect({ key: PARTNER_KEY, value });
  setLastSelect({ key: CUSTOMER_KEY, value: '' });
  setLastSelect({ key: CUSTOMERS_KEY, value: [] });
  setLastSelect({ key: PERFORMANCE_PARTNER, value: [value] });
  setLastSelect({ key: PERFORMANCE_CUSTOMERS, value: [] });
};

export const setLastSelectCustomer = (value) => {
  setLastSelect({ key: CUSTOMER_KEY, value });
  setLastSelect({ key: CUSTOMERS_KEY, value: [value] });
  setLastSelect({ key: PERFORMANCE_CUSTOMERS, value: [value] });
};

export const getLastSelectByKey = (key) => {
  const lastSelected = getLastSelect();
  if (Array.isArray(lastSelected)) {
    const lastSelectedItem = lastSelected.find((i) => i.key === key);
    if (lastSelectedItem) { return lastSelectedItem.value; }
  }
};

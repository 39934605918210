export const containerSx = {
  maxWidth: '283px',
  p: '7px',
  pt: '15px',
  fontSize: '14px',
  textAlign: 'center',
  color: 'primary.red',
  position: 'relative',
  maxHeight: '80px',
  overflow: 'auto',
};

export const closeIconSx = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  fontSize: '10px',
  cursor: 'pointer',
};

export const successContainerSx = {
  maxWidth: '283px',
  pt: '15px',
  p: '7px',
  fontSize: '14px',
  textAlign: 'center',
  color: '#63D4A0',
  position: 'relative',
  maxHeight: '80px',
  overflow: 'auto',
};

export const arrowUpSx = {
  width: 0,
  height: 0,
  borderLeft: '30px solid transparent',
  borderRight: '0px solid transparent',
  borderBottom: '20px solid #fff',
  position: 'fixed',
  top: '-20px',
  right: '30px',
};
